<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>

      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :start="calendario.inicio"
          :end="calendario.fim"
          color="secondary"
          :events="events"
          :type="type"
          event-color="#eeeeee"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        >
          <template v-slot:event="{ event }">
            <div class="d-flex align-center px-1 py-0">
              <v-icon class="" small :color="event.tema.cor">mdi-circle</v-icon>

              <div class="black--text mx-2">
                <strong>
                  {{ event.name }}
                </strong>
              </div>
              <v-card
                class="d-flex align-center mr-1"
                style="padding: 2px;"
                v-for="(rede_social, index) in event.redeSocial"
                :key="index"
                width="16px"
                height="14px"
                flat
              >
                <v-img contain :src="rede_social.icone" class="mr-1"></v-img>
              </v-card>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>

    <AddEditPostagemMidiaSociais
      v-if="dialogAddPostagem && calendario.status <= 3"
      :dialogAddPostagem.sync="dialogAddPostagem"
      :postagem_id="postagem_id"
      :edit="edit"
      @fetch-postagem="fetchPostagens"
    />
  </v-row>
</template>

<script>
import { fetchCalendariosPostagens } from "@/api/colaborador/midias_sociais_calendario_postagem.js";
import { parseISO } from "date-fns";

export default {
  name: "CalendariosPostagens",

  components: {
    AddEditPostagemMidiaSociais: () =>
      import("./AddEditPostagemMidiaSociais.vue"),
  },

  props: {
    calendario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      events: [],
      dialogAddPostagem: false,
      edit: false,
      postagem_id: null,
    };
  },

  computed: {
    calendario_id() {
      return this.$route.params.calendarioId;
    },
  },

  methods: {
    print() {
      return print();
    },
    showEvent(event) {
      this.postagem_id = event.event.id;
      this.dialogAddPostagem = true;
      this.edit = true;
    },

    getMidiaSocialCalendariosPostagens() {
      this.loading = true;
      fetchCalendariosPostagens(`?calendario=${this.calendario_id}`)
        .then((response) => {
          this.events = response.map((item) => {
            let event = {};
            event.name = item.assunto;
            event.start = parseISO(`${item.data}` + " " + `${item.hora}`);
            event.tema = item.tema;
            event.formato = item.formato;
            event.redeSocial = item.redes_sociais;
            event.timed = true;
            event.id = item.id;
            return event;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    fetchPostagens() {
      this.$emit("fetch-postagem");
    },
  },
  mounted() {
    this.getMidiaSocialCalendariosPostagens();
  },
};
</script>

<style lang="scss"></style>
